<template>
  <section class="cate-orange">
    <div class="profile-common">
      <!-- info overview -->
      <div class="wrap-overview">
        <div class="content-info">
          <!-- name -->
          <h2>{{ profile.nickname }}<span>さん</span></h2>

          <!-- point -->
          <div class="point-user">
            <!-- availability points -->
            <div>
              <p>利用可能ポイント</p>
              <strong v-if="user_point.total_point_amount < 10000000">
                {{ user_point.total_point_amount | numberShort }}P
              </strong>
              <strong v-else>
                {{ 9999999 | numberWithCommas }}+P
              </strong>
            </div>

            <!-- waiting point for approval -->
            <div>
              <p>判定中ポイント</p>
              <strong>
                {{ (infoStatisticCommon.waiting_pointback_amount || 0) | numberShort(',', 'P') }}
              </strong>
            </div>
          </div>
        </div>
      </div>

      <!-- invite friend -->
      <section class="section-profile">
        <!-- title -->
        <h3 class="title">
          <img src="../../assets/images/icon/icon-users.svg" alt="icon">お友達紹介
        </h3>

        <!-- show info -->
        <div class="content-invite">
          <div class="info">
            <p>今月の紹介人数</p>
            <strong>{{ infoStatisticCommon.total_invitee_in_month }}<span>人</span></strong>
          </div>
          <div class="info">
            <p>累計紹介人数</p>
            <strong>{{ profile.invite_friend_amount || 0 }}<span>人</span></strong>
          </div>
          <div class="invite-friend">
            <router-link to="/invite-friend">
              <img src="../../assets/images/icon/icon-user-invite.svg" alt="icon">お友達に紹介する
            </router-link>
          </div>
        </div>
      </section>
    </div>

    <!-- title -->
    <div class="section-profile">
      <router-link
        class="btn section-profile-btn"
        to="/gift-code-input"
        v-if="isOnNativeApp"
      >
        ギフトコード入力
        <ChevronRightIcon size="24"/>
      </router-link>

      <h3 class="title">
        <img src="../../assets/images/icon/icon-book-open.svg" alt="icon">ポイント通帳
      </h3>
      <!-- tab point detail -->
      <div class="wrap-tab-point">
        <div class="menu-tab">
          <router-link to="/profile/mypage" class="active">
            <list-icon size="1.5x" class="custom-class"></list-icon>ポイント明細
          </router-link>

          <router-link to="/profile/point-exchange-history">
            <img src="../../assets/images/icon/icon-history-inactive.svg" alt="icon">ポイント交換履歴
          </router-link>
        </div>

        <!-- content tab detail point -->
        <div class="wrap-note">
          <p class="note">※過去6ヶ月分まで表示</p>
          <p class="note">※X(旧Twitter)シェア、友達紹介そのほかボーナスとして付与されたボーナスポイントはポイント失効日延長の対象外となりますので、ご了承ください。</p>
        </div>

        <!-- message data empty -->
        <p class="data-empty"
           v-if="pagination.total === 0">該当するデータが見つかりませんでした。</p>

        <table v-else class="table-common-profile">
          <tr>
            <th>ステータス</th>
            <th>利用日</th>
            <th>付与日</th>
            <th>サービス名</th>
            <th>ポイント</th>
          </tr>
          <tr v-for="item in transactionHistory"
              :key="item.id">
            <td>
              <p class="sp">ステータス</p>
              <img :src="require(`../../assets/images/icon/icon-status-0${item.status}.svg`)" alt="icon">
              <span>{{ displayStatus(item.status) }}</span>
            </td>
            <td>
              <p class="sp">利用日</p>
              {{ displayTime(item) | formatDate('MM/DD') }}
            </td>
            <td>
              <p class="sp">付与日</p>
              {{ item.approval_date | formatDate('MM/DD')}}
            </td>
            <td>
              <figure v-if="item.transaction_type === 1">
                <img :src="(item.advertise && item.advertise.file ? item.advertise.file.path : '') | imageEmpty" alt="thumbnail">
              </figure>
              <p class="line-clamp-01">
                {{ displayServiceName(item) }}
              </p>
            </td>
            <td>
              {{ item.pointback_amount | numberShort(',', 'P') }}
            </td>
          </tr>
        </table>

        <!-- pagination -->
        <div v-if="pagination.totalPages > 1" class="wrap-pagination">
          <Pagination :total-page="pagination.totalPages"
                      @change-page = changePage
                      :current-page="pageActive" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { ListIcon, ChevronRightIcon } from 'vue-feather-icons'
import Pagination from '@/components/Pagination'
import { mapActions, mapState } from 'vuex'
import { AFAD_STATUS } from '@/enum/options'
import store from '@/store'
import Common from '@/mixins/common.mixin'

export default {
  name: 'PointExchange',

  components: {
    Pagination,
    ListIcon,
    ChevronRightIcon
  },

  mixins: [Common],

  beforeRouteEnter (to, from, next) {
    const params = {
      perPage: 12,
      page: 1,
      with: 'advertise'
    }
    store.dispatch('user/getTransactionHistory', params).then(_ => next())
  },

  computed: {
    ...mapState('auth', ['profile', 'user_point']),
    ...mapState('user', ['infoStatisticCommon', 'pagination', 'transactionHistory']),

    isOnNativeApp () {
      return this.checkWebview()
    }
  },

  data () {
    return {
      params: {
        page: 1,
        perPage: 12,
        with: 'advertise'
      },
      pageActive: 1
    }
  },

  methods: {
    ...mapActions('user', ['getTransactionHistory']),

    displayStatus (status) {
      return AFAD_STATUS.find(x => x.prefecture_code === status).status
    },

    displayServiceName (item) {
      switch (item.transaction_type) {
        case 1:
          return item.advertise_name
        case 5: case 9:
          return item.item_name
        case 6:
          return '友達紹介キャンペーン'
        case 8:
          return 'シェア'
        case 10: case 11: case 13:
          return item.postback_title || ''
        case 12:
          return item?.chibijob_gift_title || ''
        default:
          return ''
      }
    },

    displayTime (item) {
      switch (item.transaction_type) {
        case 1:
          return item.created_at
        case 5: case 9:
          return item.approval_date
        case 6:
          return item.updated_at
        case 8: case 10: case 11: case 13:
          return item.created_at
        case 12:
          return item?.approval_date || ''
        default:
          return ''
      }
    },

    changePage (page) {
      this.params = {
        ...this.params,
        page: page
      }

      this.pageActive = page
      this.fetchList(this.params)
    },

    // FETCH LIST
    fetchList (params = {}) {
      this.getTransactionHistory(params)

      // scroll to top when change page
      window.scrollTo({ top: 0, behavior: 'smooth' })
    }
  }
}
</script>

<style lang="scss" scoped>
.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: A1 Gothic M, "A1 Gothic M", "Noto Sans JP", sans-serif;
  width: 100%;
  height: 51px;
  background: $orange-1;
  border-radius: 6px;
  color: $white;
  margin: auto;
  cursor: pointer;
}

.data-empty {
  text-align: center;
  padding: 20px 15px;
  font-weight: bold;
}

.wrap-note {
  padding: 33px 0;
  background: #FAFAFA;
  & > .note {
    padding: 0 24px 12px;
    text-align: left;
    &:last-child {
      padding-bottom: 0;
    }
  }
  @media #{$info-phone} {
      padding: 21px 0;
      border-bottom: 1px solid #CACBC6;
      & > .note {
        border-bottom: none;
        padding:0 21px;
        &:first-child {
          padding-bottom: 10px;
        }
      }
  }
}

.section-profile-btn {
  margin-bottom: 40px;
}
</style>
